.spinner
	display: inline-block
	line-height: 1
	vertical-align: middle
	// background: gray
	margin: 0 .5em 0 .5em

.spinner-svg
	width: 1.5em
	height: 1.5em
	animation: rotate 2s linear infinite

.spinner-circle
	stroke: #0074d9 // Change the color as needed
	stroke-dasharray: 80
	stroke-dashoffset: 60
	animation: dash 1.5s ease-in-out infinite, color-change 3s linear infinite

@keyframes rotate
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)

@keyframes dash
	0%
		stroke-dashoffset: 80
	50%
		stroke-dashoffset: 20
	100%
		stroke-dashoffset: 80

@keyframes color-change
	0%, 100%
		stroke: #0074d9 // Change the color as needed
	50%
		stroke: #ff4136 // Change the color as needed