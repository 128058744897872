

.EntriesMenu
	margin-bottom: calc(var(--padding) * 0.5) !important

.Pagination
	button.last
		margin-right: var(--padding)


.Entries
	width: fit-content
	display: grid
	margin: var(--pad) 0
	column-gap: var(--pad05)
	row-gap: 1px
	> div
		grid-column: 1 / -1
		display: grid
		grid-template-columns: subgrid
		> *
			padding: var(--pad05)
			&:first-child
				padding-right: 0
			&:last-child
				padding-right: var(--pad)
	.EntrySnippet,
	.EntriesSnippetHeaders
		grid-column: snippet-start / snippet-end
		display: grid
		grid-template-columns: subgrid
		column-gap: var(--pad15)
	.EntriesSnippetHeaders
		font-size: small

.EntriesEntry
	background: var(--itemColor)
	&:hover
		outline: 1px solid var(--linkColor)
	
.SnippetModified
	font-size: small



// Entry Page


.EntryTitle
	margin-bottom: 0

.EntryMenu
	display: flex
	align-items: baseline
	padding: .5em 0
	background: var(--mainColor)
	border-bottom: 1px solid var(--separatorColor)
	display: flex
	column-gap: 1.5em
	margin-bottom: 1.5em
	&.sticky
		position: sticky
		top: 0
		z-index: 100
	.Save
		display: flex
		align-items: baseline
		.Changes
			font-style: italic
		button
			margin-left: 1em

.EntriesSearch
	display: flex
	column-gap: var(--padding)
	input[type=search]
		padding: .25em

.URIfield
	margin: 1rem 0
	> div
		display: flex
		align-items: center
		input
			flex: 1
			margin-right: .5rem
	.Legend
		align-items: baseline
		h4
			margin-right: .5em
		a
			font-size: small


.EntryFields
	display: flex
	flex-direction: column	
	margin: 1.5em 0
	row-gap: 1.5em



.EntrySnippetEdit
	display: flex
	flex-direction: column



// Entry Changes

.EntryChanges
	margin: 0

.EntryChangesSnap
	.userTimestamp
		margin-bottom: .5em
		.avatar
			width: 1.5em
		.username
			font-weight: 500

.EntryChange
	h4
		margin: 0
	.deltas
		display: flex
		gap: .25em
		align-items: baseline
		.action
			width: 1.25em
			text-align: center
		.value
			white-space: pre-wrap
		.added
			background: lightgreen
		.removed
			background: salmon



.EntryTags
	display: flex
	flex-direction: row
	flex-wrap: wrap



.BulkEditEntry
	border: 1px solid #ccc
	padding: .25em
	