
@use 'normalize.css'

@use 'tasks'
@use 'build'
@use 'logs'
@use 'files'
@use 'entries'
@use 'fields'
@use 'source'
@use 'spinner'
@use 'users'
@use 'layout'
@use 'forms'
@use 'urls'
@use 'modal'
@use 'ai'
@use 'imports'
@use 'utilities'
@use 'navbar'

body
	font-family: 'Helvetica Neue', sans-serif
	--backgroundColor: #ddd
	--mainColor: #eee
	--itemColor: #fafafa
	// --itemColorHover: 
	--textColor: #111
	--linkColor: hsl(226deg 85% 53%)
	--visitedColor: hsl(226deg 77% 37%)
	--activeColor: hsl(202deg 85% 52%)
	--separatorColor: #bbb
	--padding: 1rem
	--pad01: .1rem
	--pad05: .5rem
	--pad: 1rem
	--pad15: 1.5rem
	--w-sm: 10rem
	--w-md: 20rem
	--w-lg: 36rem
	--w-xl: 50rem
	background: var(--backgroundColor)
	color: var(--textColor)
	min-height: 100vh
	display: flex
	

// Inputs

select,
textarea,
button
	padding: .2em .25em

input[type="text"],
input[type="number"],
input[type="search"],
input[type="password"],
input[type="email"],
select,
textarea,
.fixWrap
	border: 1px solid #ccc
	border-radius: .25em
	padding: .25em
	&:focus
		border-color: var(--linkColor)
		outline: 1px solid var(--linkColor)
	&:disabled
		background: #f8f8f8
		opacity: .5
		cursor: not-allowed

.fixWrap
	display: flex
	align-items: baseline
	padding: 0
	background: #f8f8f8
	input
		flex: 1
		border-color: transparent
	.suffix,
	.prefix
		padding-left: .5em
		padding-right: .5em
	


button
	background: #f8f8f8
	font-weight: 500
	padding-left: .5em
	padding-right: .5em
	border: 1px solid var(--linkColor)
	&:hover
		border: 1px solid var(--linkColor)
		color: var(--linkColor)
	&:disabled
		border: 1px solid #555
		color: var(--textColor)
		opacity: .5
		cursor: not-allowed
	&.delete
		background: transparent
		box-shadow: none
	&.primary
		background: var(--linkColor)
		color: white
		border-color: var(--visitedColor)
		&:hover
			background: var(--visitedColor)
	&.small
		padding: .1em .5em


a
	color: var(--linkColor)
	text-decoration: none
	&:hover
		text-decoration: underline
	&:visited
		color: var(--visitedColor)


hr
	margin: 1.5rem 0
	border: none
	border-bottom: 1px solid var(--separatorColor)
	&.dangerZone
		margin-top: 3rem

h1, h2, h3, h4
	font-weight: 500

h1
	font-size: xx-large
h2
	font-size: x-large
h3
	font-size: large
h4
	font-size: medium


p
	margin: 0 0 1em 0



// fieldsets
	
fieldset
	border: 0
	padding: 0.01em 0 0 0
	margin: 0
	min-width: 0

legend
	float: left
	display: table
	padding: 0
	margin: 0 0 .25em 0
	width: 100%
	font-weight: 500
	&+ *
		clear: both

body:not(:-moz-handler-blocked) fieldset
	display: table-cell
