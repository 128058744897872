
#Navbar
	flex: 0 0 auto
	min-width: 10em
	max-width: min(25vw, 16em)
	display: flex
	flex-direction: column
	padding: .5rem 1rem
	margin-bottom: 1rem
	> a
		padding: .5rem
		font-weight: 500
		text-decoration: none
		&:hover
			color: var(--textColor)
		&.active
			background: var(--itemColor)
			color: var(--textColor)
	.CollectionLink
		display: flex
		column-gap: .4em
	#AddCollectionLink
		font-size: smaller
		font-weight: 400
		margin-bottom: .25rem
	.UserLink
		display: flex
		align-items: center
		column-gap: .5em
		.avatar
			border-radius: 50%
			height: 2.4em
	hr
		margin: .25rem 0
		border-bottom: 1px solid #bbb

#SiteLogo
	display: flex
	gap: .5rem
	align-items: center
	padding: .5rem
	padding-right: 0 !important
	font-size: 1.33rem
	font-weight: 500
	color: var(--textColor)
	img
		max-height: 2rem
		margin-left: -.5rem
		width: auto
	&.active
		background: none !important

#SiteName
	overflow: hidden
	text-overflow: ellipsis
	

#BuildLink
	display: flex
	flex-wrap: wrap
	row-gap: .25rem
	column-gap: .5rem
	align-items: baseline

#BuildInfo
	display: inline-block
	white-space: nowrap
	font-weight: 400
	font-size: small
	color: var(--textColor)
	text-overflow: ellipsis
	overflow: hidden
	max-width: 100%
