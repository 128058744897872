
.ImportMap
	display: grid
	grid-template-columns: 1fr 1fr
	grid-template-rows: auto
	background: #ddd
	padding: 1em
	margin: 1em 0
	border: 1px solid #ccc
	column-gap: 1.5em
	row-gap: .25em
	> div
		background: #f3f3f3

.IMHeader
	grid-column: 1 / 3
	background: none !important

.IMLink
	display: grid
	grid-column: 1 / 3
	grid-template-columns: subgrid
	> div
		background: #fff
		padding: .25em
	.IMSource
		> div
			.ColumnStats
				position: absolute
				background: #ccc
				padding: .5em
				border: 1px solid gray
				visibility: hidden
			&:hover
				.ColumnStats
					visibility: visible

.IMFooter
	grid-column: 1 / 3
