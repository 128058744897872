
.FieldsList
	grid-template-columns: repeat(6, fit-content(100%))
	margin-bottom: 1em
	.FieldItem
		grid-column: 2 / -2
		display: grid
		grid-template-columns: subgrid
		padding: 0 !important
	.FieldItemEditor
		grid-column: 2 / -1
		margin-bottom: 1em



// .DraggableFieldItem
// 	background: #bff
// 	display: flex
// 	flex-direction: row
// 	column-gap: 1rem
// 	margin: .25em 0


.FieldsEditor
	display: flex
	flex-direction: column
	row-gap: 1rem

.FieldsEditorItem
	background: #ccc
	display: flex

.FieldsEditorItemContent
	flex: 1
	.InputPreview
		background: #ddd
		padding: 1rem

.FieldsEditorItemOptions
	padding: .5rem

	
.FieldEditor
	background: var(--itemColor)
	padding: .5em


// Fields

.Field
	margin: 0
	padding: 0
	display: flex
	flex-direction: column
	row-gap: .5em
	// background: var(--itemColor)

.FieldLabel
	// background: #fab
	display: flex
	flex-direction: row
	flex-wrap: wrap
	column-gap: 1em
	h4
		margin: 0
		border-bottom: none

.FieldInput
	.input
		width: 100%


// 
// Field Inputs
// 


.MultiInputList
	width: auto !important
	grid-template-columns: min-content 1fr min-content

// STRUCT

.STRUCTinput
	max-width: var(--w-xl)
	
// .STRUCTfields
// 	background: #fab
// 	// max-width: var(--w-xl)
		
.STRUCTfieldsEditor
	border-left: .25em solid var(--separatorColor) !important
	padding: .5em


// ID

.IDinput
	.input
		max-width: var(--w-md)

// URI

.URIinput
	.input
		max-width: var(--w-xl)

.URIextras
	.RedirectsList
		grid-template-columns: repeat(4, fit-content(100%)) !important

// TEXT

.TEXTinput
	.input
		max-width: var(--w-xl)

// HTML

.HTMLinput
	display: flex
	column-gap: .5em
	div.input
		flex: 1
		max-width: var(--w-xl)
		textarea
			font-family: monospace
			font-size: 1.1em
			width: 100%
	.HTMLinputOutput
		flex: 1
		background: var(--itemColor)
		padding: var(--pad)
		img
			max-width: 100%
	.HTMLinputButtons
		display: flex
		flex-direction: column

.HTMLinputOutput img
	display: block
	&.left
		float: left
		margin: 0 1.5em 1.5em 0
	&.right
		float: right
		margin: 0 0 1.5em 1.5em
		&::after
			content: ""
	&.stretch
		width: 100%
		margin: 1em 0 1.5em 0
	&.center
		display: block
		margin: 1em auto 1.5em auto
	&.small
		max-width: 33% !important
	&.medium
		max-width: 50% !important
	&.large
		max-width: 66% !important


// REFERENCE

.REFERENCEgrid
	grid-template-columns: repeat(var(--columns), fit-content(100%))
	.EntrySnippet
		grid-column: 1 / -2
		display: grid
		grid-template-columns: subgrid

// JSON

.JSONinput
	.input
		max-width: var(--w-xl)


// .STRUCTinput
// 	background: blue
// 	.input
// 		border-left: .25em solid var(--separatorColor)


// .TextInput
// 	display: flex
// 	flex-direction: column

// .BooleanInput
// 	display: flex
// 	column-gap: 1.5rem

// .SelectionRadioInput
// 	display: flex
// 	flex-wrap: wrap
// 	row-gap: .5em
// 	column-gap: 1.5em

// .InputInfo
// 	margin-top: .5em

// .ReferenceInputEntries
// 	border: 1px solid #aaa
// 	padding: 0 .5rem
// 	padding-bottom: .5rem

// .ReferenceEntry
// 	background: #fff
// 	display: flex
// 	column-gap: .5rem
// 	// user-select: none
// 	// pointer-events: none
// 	> .snippet
// 		flex: 1



// Multi-Field Inputs

.MultiFieldSortableList
	display: flex
	flex-direction: column
	row-gap: .5rem
	> .SortableItem
		background: #fafafa
		padding: .25em
		display: flex
		column-gap: .5rem
		> .SortableHandle
			cursor: grab
		> .FieldInput
			flex: 1
