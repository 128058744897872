
.FilesWrap.isModal
	background: #eee
	border: solid 2px #aaa
	position: fixed
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	width: 90vw
	height: 90vh
	display: flex	
	z-index: 1000
	padding: 1rem

.Files
	display: grid
	width: 100%
	grid-template-columns: auto min-content
	grid-template-rows: min-content 1fr
	column-gap: 2rem
	row-gap: 1rem

.FilesPath
	border-bottom: 1px solid gray
	grid-column: 1 / 3 
	padding-bottom: .5em
	> span
		margin: 0 .15em

.FilesFolder
	grid-row: 2 / 3
	grid-column: 1 / 2
	.Folders
		display: flex
		flex-direction: column
		row-gap: .5rem
		margin-bottom: 1rem
		.FolderLink
			background: var(--itemColor)
			padding: .4em .5em
	.Files
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(10em, 1fr))
		row-gap: 2rem
		column-gap: 2rem


.ImageFile
	background: var(--itemColor)
	display: flex
	flex-direction: column
	justify-content: space-between
	> a
		flex: 1
		display: flex
		flex-direction: column
		justify-content: center
		&:hover
			img
				outline: .25em solid var(--visitedColor)
		img
			max-width: 100%
			display: block
	> label
		padding: .5em
		display: flex
		column-gap: .5em
		align-items: flex-start
		&:hover
			color: var(--visitedColor)
		> span
			word-break: break-all



// Options

.FilesOptions
	grid-column: 2 / 3
	display: flex
	flex-direction: column
	align-items: flex-start
	row-gap: 1.5rem

.FilesUpload
	input
		font-size: small
		max-width: 15em

// Modal

.FileModal
	background: #eee
	border: solid 2px red
	position: fixed
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	width: 90vw
	// height: 100vh
	display: flex
	.Image
		padding: 1rem
		flex: 4
		overflow-y: scroll
		img
			max-width: 100%
	.Info
		background: #ddd
		padding: 1rem
		min-width: min-content
		max-width: 20rem


// File Field Input

.FileFieldItem
	img
		&:hover
			outline: solid 2px blue