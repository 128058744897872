
.Form
	display: grid
	grid-template-columns: 1fr
	gap: .75em
	max-width: 42em
	fieldset
		display: flex
		flex-direction: column
		legend
			margin-bottom: .25em
			font-weight: 500
		> .w-fit
			align-self: flex-start
			width: fit-content(100%)
	.RadioInput
		display: flex
		column-gap: 1.25em
		row-gap: .25em
		flex-wrap: wrap
	.Submit
		display: flex
		flex-direction: row
		justify-content: flex-start
		column-gap: .5em
		padding: .5em 0



	