
.Logs
	display: flex
	flex-direction: column

	
.LogLine
	display: flex
	border-bottom: 1px solid var(--separatorColor)
	align-items: baseline
	padding: .25em 0
	.LogDate
		white-space: nowrap
		margin-right: 1em
		font-family: monospace
	.LogAvatar
		display: inline-block
		vertical-align: middle
		border-radius: 50%
		width: 1.75em
		margin-right: .25em
	.LogDesc
		line-height: 1.2