
.inlineFlex
	display: inline-flex
	align-items: baseline
	column-gap: var(--pad05)

.Icon
	width: 1em
	height: 1em
	display: inline-block
	vertical-align: middle
	line-height: 1
	fill: currentColor
	margin-top: -.15em


.GridTable
	--columnGap: var(--pad)
	--cellPad: var(--pad05)
	width: fit-content
	display: grid
	// margin: var(--pad) 0
	column-gap: var(--columnGap)
	row-gap: var(--pad01)
	// grid-template-columns: repeat(auto-fit, fit-content(100%))
	> .Item
		grid-column: 1 / -1
		display: grid
		grid-template-columns: subgrid
		align-items: baseline
		background: var(--itemColor)
		column-gap: var(--columnGap)
		&:hover
			outline: 1px solid var(--linkColor)
		> *
			align-items: baseline
			padding: var(--cellPad)
			// &:first-child
			// 	padding-left: var(--pad)
			// &:last-child
			// 	padding-right: var(--pad)
	> .SubItem
		grid-column: 1 / -1


// Details Button

.DetailsButton
	summary
		color: var(--linkColor)
		cursor: pointer
		&:hover
			text-decoration: underline

code.null
	color: #888
	font-style: italic


.small
	font-size: small

.danger
	color: orangered

.delete
	color: red
	cursor: pointer
	border: 1px solid red
	border-radius: .25em

span.success
	background: #ded
	display: inline-block
	padding: .1em .25em

.width-sm
	max-width: 24em

.w-100
	width: 100%

pre
	white-space: pre-wrap
	&.nowrap
		white-space: nowrap

.monospace
	font-family: monospace

.block
	display: block

.float-right
	float: right

.box
	border: var(--separatorColor) 1px solid
	padding: .5em
	margin: 1em 0
	background: #f5f5f5

.Alert
	border: 3px solid red
	border-radius: .5em
	padding: 1rem
	background: #fee
	margin: 1rem 0
	color: red
	&.isSticky
		position: sticky
		top: 0
		z-index: 100
	> p
		font-weight: 500
	details
		pre
			white-space: pre-wrap

.warning
	background: #ff8
	padding: .1em .25em


.Spinner
	display: inline-block
	width: 1.5em
	height: 1.5em



.ItemsList
	display: flex
	flex-direction: column
	gap: 1em
	> *
		background: var(--itemColor)
		padding: 1em


.avatar
	border-radius: 50%
	vertical-align: middle
	display: inline-block
	

.tooltip
	border-bottom: 1px dotted gray
	cursor: default
	&:hover::after
		content: attr(data-title)
		position: absolute
		transform: translate(-100%, 100%)
		z-index: 100
		background-color: var(--textColor)
		color: white
		font-size: .75rem
		font-family: sans-serif 
		font-weight: 500
		padding: .25em .4em .2em .4em !important
		border-radius: .25em		
		white-space: nowrap


.EmojiPickerReact button
	background: none
	box-shadow: none
	border: none
	padding: none

