
.UrlsList
	grid-template-columns: repeat(4, fit-content(100%))
	.UrlsListItem
		display: subgrid
	// 	.redirectsBtn
	// 		white-space: nowrap
	// 		font-family: monospace
	// 		cursor: pointer
	// .UrlsListRedirects
	// 	display: block
	// 	grid-column: 2 / -1


.RedirectsList
	grid-template-columns: repeat(6, fit-content(100%)) !important
	.UrlsListItem
		.redirectsBtn
			white-space: nowrap
			font-family: monospace
			cursor: pointer
	.UrlsListRedirects
		display: block
		grid-column: 2 / -1


