
#BuildLog
	background: #111
	white-space: pre-wrap
	font-family: monospace
	padding: .5rem
	margin: 1rem 0
	color: #bbb

#BuildStatus
	.success
		color: green
	.error
		color: red
	.active
		color: blue

.DeveloperToken
	h3
		margin: .25em 0
	p
		margin: .25em 0 .5em 0
	button.delete
		float: right
	code
		display: block
		word-break: break-all
