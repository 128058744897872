
.TaskList
	grid-template-columns: repeat(5, fit-content(100%)) 1fr
	.TaskEditor
		display: flex !important
		flex-direction: column

.TaskEditor	
	padding: .5em
	background: var(--itemColor)
	margin: 0 0 .5em 0
	.InOutFields
		display: flex !important
		flex-direction: row
		column-gap: 2em
		ul
			margin: 0
			list-style: none
			padding: .25em


.TaskChanges
	// width: auto
	background: var(--itemColor)
	display: flex
	column-gap: 1em
	align-items: flex-start
	padding: .5em
	margin-bottom: .5em
	margin-left: .5em
	.TaskChangesList
		flex: 1
	// grid-template-columns: repeat(2, fit-content(100%))
	// grid-template-columns: 1fr fit-content(100%)
	// margin: 0 0 .75em .5em !important


.BulkEntries
	grid-template-columns: fit-content(100%) repeat(var(--columns), fit-content(100%))
	.EntrySnippet
		grid-column: 2 / -1
		display: grid
		grid-template-columns: subgrid
	> .SubItem
		margin: 0 !important
		padding: 0 !important