

.EntryAI
	margin: 1em 0

.AITaskEditor
	background: #ddd
	padding: 1em
	.AITaskIOFields
		display: flex
		flex-direction: row
		> div
			flex: 1
			display: flex
			flex-direction: column


.AITasks
	display: flex
	flex-direction: column
	row-gap: 1em
	margin: 1em 0

.AITask
	background: #ddd
	padding: 1em
	h4
		margin: 0


.AITaskUpdateManager
	display: flex
	flex-direction: column
	row-gap: 1em
	margin: 1em 0 0 0
	> div
		border-top: 1px solid #aaa
		padding: .5em 0