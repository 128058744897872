
#AuthScreen
	max-width: 30rem
	margin: 4rem auto
	padding: 1em


#App
	display: flex
	flex: 1
	// justify-content: center
	// width: 100%


#Main
	flex: 1
	background: var(--mainColor)
	padding: var(--padding)
	padding-left: calc(var(--padding) * 2)
	padding-bottom: calc(var(--padding) * 10)


header
	margin-bottom: var(--padding)
	border-bottom: 1px solid var(--separatorColor)
	h1
		margin: 0
	nav
		display: flex
		a
			font-weight: 500
			padding: calc(var(--padding) * .75)
			&:first-child
				padding-left: 0
			&.active
				text-decoration: none
				color: black


.Menu
	display: flex
	flex-direction: row
	align-items: baseline
	column-gap: calc(var(--padding) * 1.5)
	margin: var(--padding) 0