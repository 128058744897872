

// .overflowWrapper
// 	display: flex
// 	overscroll-behavior-x: contain
// 	overflow-x: auto
// 	width: 100%
	// margin-inline: calc(var(--page-gutter) * -1)
	// padding-inline: var(--page-gutter)

.CollectionSource
	border-collapse: collapse
	th, td
		border: 1px solid #aaa
		padding: .25em 1em .25em .5em
	th
		text-align: left
		vertical-align: top
		font-weight: normal
		padding-bottom: .5em !important
	tbody
		td
			white-space: nowrap
			font-family: monospace

.ColumnType
	display: flex
	flex-wrap: wrap
	gap: .5em


.ColumnStats
	margin: .5em 0
	white-space: nowrap
	dt
		font-size: small
	dd
		margin: 0 0 .5em 0
		
