
#UsersList
	width: min-content

.UserSnippet
	display: flex
	gap: 1rem
	align-items: center
	padding-right: 1em
	.avatar
		border-radius: 50%
		width: 96px
	h3, p
		margin: .5em 0

.UserCard
	display: flex
	width: min-content
	align-items: center
	column-gap: 1em
	margin: 1em 0
	.avatar
		border-radius: 50%	
		height: 8em
	p, 
	h3
		margin: .5em 0
